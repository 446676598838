<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card class=" px-2 py-2 pb-5">
          <v-col class="pl-0 pr-0 pb-1">
            <v-row>
              <v-spacer />
              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="2"
                class="pr-3   py-0 my-0"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  outlined
                  dense
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      v-model="startDate"
                      label="Tarih"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="2"
                class="pr-3 py-0 my-0"
              >
                <v-select
                  v-model="alarmCodeModel"
                  :items="alarmCodeList"
                  :label="$t('Alarm Türü')"
                  multiple
                  outlined
                  dense
                  :placeholder="$t('Durum')"
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >
                      {{ item.text }}
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ alarmCodeModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="2"
                lg="1"
                class="pr-3 py-0 my-0"
              >
                <v-select
                  v-model="alarmStatusModel"
                  :items="alarmStatus"
                  :label="$t('')"
                  multiple
                  outlined
                  dense
                  :placeholder="$t('Durum')"
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >
                      {{ item.text }}
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ alarmStatusModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="2"
                lg="1"
                class="pr-3 py-0 my-0"
              >
                <v-select
                  v-model="alarmTypeModel"
                  :items="alarmType"
                  :label="$t('')"
                  multiple
                  outlined
                  dense
                  :placeholder="$t('Tip')"
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >
                      {{ item.text }}
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ alarmTypeModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="4"
                lg="2"
                class="pr-3 py-0 my-0"
              >
                <v-select
                  v-model="santralModel"
                  :items="santralDataList"
                  :label="$t('santral')"
                  :placeholder="$t('santral')"
                  multiple
                  outlined
                  dense
                >
                  <template v-slot:selection="{item, index}">
                    <span
                      v-if="index === 0"
                      style="max-width: 120px"
                      class="d-inline-block text-truncate"
                    >
                      {{ item }}
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ santralModel.length - 1 }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="2"
                class="pr-3 py-0 my-0"
              >
                <v-text-field
                  v-model="search"
                  :label="$t('Ara')"
                  outlined
                  dense
                  :placeholder="$t('Ara')"
                />
              </v-col>
              <v-col
                sm="4"
                md="2"
                lg="1"
                class="pr-3 py-0 my-0"
              >
                <v-btn
                  color="primary"
                  @click="setParams"
                >
                  Filtrele
                </v-btn>
              </v-col>

              <v-col
                sm="4"
                md="1"
                lg="1"
                class="pr-3 py-0 my-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      fab
                      dark
                      small
                      color="cyan"
                      v-on="on"
                      @click="exportDocument"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Export to Excel</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-col
      cols="12"
      class="fullgridtable pl-0 pr-0 pb-1"
    >
      <v-card
        width="100%"
        height="100%"
        class="elevation-0"
      >
        <v-data-table
          :id="santralDataTable"
          dense
          :items-per-page.sync="itemsPerPage"
          :server-items-length="itemsLength"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
            showFirstLastPage: true,
            itemsPerPageText: 'Limit'
          }"
          :options.sync="options"
          :headers="headers"
          :page.sync="currentPage"
          :loading="loading"
          loading-text="Yükleniyor... Lütfen bekleyiniz"
          :items="desserts"
        >
          <template v-slot:item.status="{item}">
            <v-chip
              v-if="item.status === 'Active'"
              color="green"
              label
            >
              <span>Aktif</span>
            </v-chip>
            <v-chip
              v-else
              color="red"
              label
            >
              Pasif
            </v-chip>
          </template>

          <template v-slot:item.alarm="{item}">
            <v-chip
              color="primary"
              small
              label
              @click="alarmDetailDessertFunc(item)"
            >
              <v-icon left>
                mdi-open-in-new
              </v-icon>
              <span style="width: 30px;">{{ item.alarm.length }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span>
            <b>Santral:</b> {{ selectedDevice.santral }} /
            <b>Cihaz:</b> {{ selectedDevice.device }} /
            <b>Alarm:</b> {{ selectedDevice.alarmCode }}
          </span>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table
            loading-text="Yükleniyor... Lütfen bekleyiniz"
            dense
            :headers="alarmDetailHeader"
            :items="alarmDetailDessert"
          >
            <template v-slot:item.status="{item}">
              <v-chip
                v-if="item.status === 'Active'"
                color="green"
                label
              >
                Aktif
              </v-chip>
              <v-chip
                v-else
                color="red"
                label
              >
                Pasif
              </v-chip>
            </template>
            <template v-slot:item.createTime="{item}">
              <span v-html="setTime(item.createTime)" />
            </template>
            <template v-slot:item.passivetime="{item}">
              <span v-html="setTime(item.passivetime)" />
            </template>
            <template v-slot:item.code="{item}">
              <span v-html="$t(item.code)" />
            </template>
            <template v-slot:item.finishMailSendDate="{item}">
              <span v-html="setTime(item.finishMailSendDate)" />
            </template>
            <template v-slot:item.mailSendDate="{item}">
              <span v-html="setTime(item.mailSendDate)" />
            </template>
            <template v-slot:item.updateTime="{item}">
              <span v-html="setTime(item.updateTime)" />
            </template>
            <template v-slot:item.severity_text="{item}">
              <span v-html="setSeverityText(item)" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>

<script>
import moment from 'moment';
import { getFarmDeviceAlarm } from '@/api/Request/Notification';
import { getAuthorizedSantralList } from '@/api/Request/santral';
import helper from '@/api/helper';

export default {
  name: 'GeneralStatsHour',
  data: () => ({
    santralModel: [],
    chartData: {},
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment()
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    menu: false,
    modal: false,
    menu2: false,
    itemsPerPage: 15,
    itemsLength: 0,
    headers: [],
    desserts: [],
    channelModel: 'STRINGBOX',
    channel: [
      { text: 'String', value: 'STRINGBOX' },
      { text: 'İnverter', value: 'INVERTER' },
    ],
    alarmType: [
      { text: 'Hata', value: 'Error' },
      { text: 'Uyarı', value: 'Warning' },
    ],
    alarmTypeModel: [],
    alarmStatusModel: [],
    alarmStatus: [
      { text: 'Aktif', value: 'Active' },
      { text: 'Pasif', value: 'Passive' },
    ],
    alarmCodeModel: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: '',
    chartText: '',
    newData: 'true',
    search: null,
    currentPage: 1,
    loading: false,
    options: {
      itemsPerPage: 15,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    params: {
      condinition: {
        startDate: moment().format('YYYY-MM-DD'),
        finishDate: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        type: [],
        status: ['Active'],
        santral: [],
      },
    },
    dialog: false,

    alarmDetailHeader: [
      { text: 'Saha', value: 'farmName' },
      { text: 'Alarm Adı', value: 'text' },
      { text: 'Alarm', value: 'alarmName' },
      { text: 'Cihaz ', value: 'device' },
      { text: 'Alt Cihaz', value: 'subdevice' },

      { text: 'Oluşturma', value: 'createTime' },
      { text: 'Pasif', value: 'passivetime' },
      { text: 'Bildirim', value: 'mailSendDate' },
      { text: 'Güncelleme', value: 'updateTime' },
      { text: 'Durum', value: 'status' },
      { text: 'Hata süresi', value: 'lifetime' },
      { text: 'önem', value: 'severity_text' },
    ],
    alarmDetailDessert: [],
    selectedDevice: {},
    alarmDetailItemsPerPage: 10,
    alarmDetailItemsLength: 0,
    alarmDetailPage: 0,
    searchTimerId: null,
    santralDataList: [],
    severity: [],
  }),
  computed: {
    alarmCodeList() {
      return this.$store.getters.alarmCodeList;
    },
  },
  watch: {

    search() {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getSearchData(this.search);
      }, 1500);
    },

    reportTypeModel() {
      this.setParams();
    },

    options: {
      handler() {
        this.loading = true;
        const {
          sortBy, sortDesc, page, itemsPerPage,
        } = this.options;

        if (sortBy.length > 0) {
          this.params.orderBys = [{ fieldName: sortBy[0], sortType: sortDesc[0] }];
        }
        this.params.skip = (page - 1) * itemsPerPage;
        this.params.limit = itemsPerPage;
        this.setParams();
      },
    },
  },
  created() { },
  mounted() {
    const { severity } = this.$store.getters;
    this.severity = severity;
    this.getAuthorizedSantral();
  },
  methods: {
    setSeverityText(item) {
      let text = item.severity_text;
      this.severity.forEach((element) => {
        if (element.key === item.severity_text) {
          text = element.text;
        }
      });
      return text;
    },
    getAuthorizedSantral() {
      this.santralDataList = [];
      getAuthorizedSantralList().then((res) => {
        if (helper.resIsEmpty(res)) {
          const santralDataList = [];
          res.data.data.forEach((item) => {
            santralDataList.push(item.santral);
          });
          this.santralDataList = santralDataList;
        }
      });
    },
    getSearchData(item) {
      this.params.search = item;
      this.getErrorTable();
    },
    // eslint-disable-next-line consistent-return
    setTime(time) {
      if (!helper.isEmpty(time)) {
        return moment(time).format('HH:mm');
      }
    },
    alarmDetailDessertFunc(item) {
      this.dialog = true;
      this.alarmDetailDessert = item.alarm;
      this.selectedDevice = {
        device: item.device,
        alarmCode: item.alarmCode,
        santral: item.santral,
      };
      this.alarmDetailItemsLength = item.alarm.length;
    },
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    setParams() {
      this.desserts = [];
      this.itemsLength = 0;
      this.finishDate = this.startDate;
      this.headers = [
        { text: 'Saha', value: 'farmName' },
        { text: 'Santral', value: 'santral' },
        { text: 'Cihaz', value: 'device' },
        { text: 'Alarm Tarihi', value: 'warningTime' },
        { text: 'Alarm', value: 'text' },
        { text: 'Alarm sayısı', value: 'alarm', sortable: false },
        { text: 'Durum', value: 'status' },

      ];

      this.finishDate = moment(this.startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      this.params.condinition.startDate = this.startDate;
      this.params.condinition.finishDate = this.finishDate;

      if (this.santralModel.length > 0) {
        this.params.condinition.santral = this.santralModel;
      }
      if (this.alarmTypeModel.length > 0) {
        this.params.condinition.type = this.alarmTypeModel;
      }
      if (this.alarmStatusModel.length > 0) {
        this.params.condinition.status = this.alarmStatusModel;
      }

      if (this.alarmCodeModel.length > 0) {
        this.params.condinition.alarmCode = this.alarmCodeModel;
      }

      if (this.farmdata) {
        this.params.prefix = this.farmdata.prefix;
        this.params.companyCode = this.farmdata.companyCode;
      }

      this.getErrorTable();
    },

    getErrorTable() {
      this.loading = true;
      const self = this;
      self.desserts = [];
      getFarmDeviceAlarm(this.params).then((res) => {
        if (res.data.success === 'true') {
          if (helper.resIsEmpty(res)) {
            self.desserts = res.data.data;
            self.itemsLength = res.data.summary.totalRecordCount;
          }

          self.forceRerender();
        }
        self.loading = false;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark .fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 68%, 9%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
