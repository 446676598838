<template>
  <div>
    <v-container class="container--fluid">
      <FarmErrorGroup />
    </v-container>
  </div>
</template>
<script>
import FarmErrorGroup from '@/views/components/Notification/FarmErrorGroup.vue';

export default {
  name: 'GeneralStatsHour',
  components: {
    FarmErrorGroup,
  },
  data: () => ({
    alarmdata: {},
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
